<template>
	<div>
		<section class="page-header">
			<nav>
				<a class="navbar-brand top-logo" href="#contact-us">
					<img src="@/assets/images/logo.png">
				</a>
				<el-button type="text" @click="showLogin" style="color: gray">LOGIN</el-button>
			</nav>
		</section>
		<section class="page-banner">
			<el-carousel height="550px" :autoplay="false" :loop="true">
				<el-carousel-item v-for="item, index in banners" :key="index">
					<div style="position:relative;">
						<el-image style=" width: 100%; height: 550px" :src="item.src" fit="cover"></el-image>
						<div class="banner-content">
							<div class="banner-title">{{item.title}}</div>
							<div class="banner-sologan">{{item.sologan}}</div>
							<template v-if="item.action">
								<div class="banner-btn" @click="handleClick(item.action)">{{item.btn_title}}</div>
							</template>
							<template v-else-if="item.href">
								<a :href="item.href">
									<div class="banner-btn">{{item.btn_title}}</div>
								</a>
							</template>
							
						</div>
					</div>
				</el-carousel-item>
			</el-carousel>
		</section>
		<section class="page-partner">
			<div class="partners-title">Partners</div>
			<div class="partners-logos">
				<img v-for="item, index in partners" :src="item.src" :alt="item.alt" width="100">
			</div>
		</section>
		<section class="page-contact-us" id="contact-us">
			<div class="page-contact-us-title">Contact Us</div>
			<div class="page-contact-us-form" v-if="!emailSent">
				<el-form ref="form" :model="form" :show-message="showMessage">
					<el-form-item prop="email" :rules="[
						  { required: true, message: 'Blank input.'},
					      { type: 'email', message: 'Please enter a valid email address.'}
					    ]">
						<el-input placeholder="Email" v-model.trim="form.email" @input="inputChanged"></el-input>
						<el-button type="primary" @click="submitForm('form')" :loading="emailLoading">Submit</el-button>
					</el-form-item>
				</el-form>
			</div>
			<div v-else class="page-contact-us-result">We will contact you as soon as possible. Please keep an eye on your email.</div>
		</section>
		<section class="page-sologan">
			<div>Spent more than 100,000,000 USD on online market</div>
		</section>
		<section class="page-footer">
			© 2024, Technology Co., Ltd
		</section>
		<el-dialog
		  class="login-dialog"
		  :visible.sync="visible"
		  center>
		  <signin :no_border="true" />
		</el-dialog>
	</div>
</template>

<script>
	import LoginComponents from '@/components/login'
	import { sendToFeishu } from '@/apis/error'
	import {
		decode
	} from 'js-base64';
	export default {
		components: {
			...LoginComponents
		},
		data() {
			return {
				visible: false,
				showMessage: false,
				form: {
					email: ''
				},
				banners: [{
					src: 'https://payssion.com/static/images/sliders/revolution/bj3.jpg',
					title: 'Fully Automated Ad Placement System',
					sologan: 'Ad Placement Made Simple',
					btn_title: 'LOGIN',
					action: () => this.showLogin()
				}, {
					src: 'https://payssion.com/static/images/sliders/revolution/bj2.jpg',
					title: 'Maximize Your Impact with Every Click',
					sologan: 'More Users, More Investment, Greater Results',
					btn_title: 'Contact Us',
					href: '#contact-us'
				}],
				partners: [{
					src: require('@/assets/images/tiktok.svg'),
					alt: 'TikTok'
				}, {
					src: require('@/assets/images/google.svg'),
					alt: 'Google'
				}, {
					src: require('@/assets/images/facebook.svg'),
					alt: 'Facebook'
				}],
				emailLoading: false,
				emailSent: false
			}
		},
		methods: {
			showLogin() {
				// this.visible = true
				window.open(`${this.$router.resolve({ path: '/login' }).href}`, '_blank');
			},
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.emailLoading = true
						const email = this.form.email
						const content = `**${email}**`
						const body = {
						    "msg_type": "interactive",
						    "card": {
						        "config": {
						            "wide_screen_mode": true,
						            "enable_forward": false
						        },
						        "elements": [
						            {
						                "tag": "div",
						                "text": {
						                    "content": content,
						                    "tag": "lark_md"
						                }
						            }
						        ]
						    }
						}
						sendToFeishu(body)
						.then(({status, data: {StatusCode}}) => {
							if (status === 200 && StatusCode === 0) {
								this.emailSent = true
							}
							this.emailLoading = false
						})
						.catch(err => {
							console.error(err)
							this.emailLoading = false
						})
					} else {
						this.showMessage = true
						return false
					}
				});
			},
			inputChanged() {
				this.showMessage = false
			},
			handleClick(action) {
				action()
			}
		}
	}
</script>

<style lang="scss">
	.page-header {
		padding: 5px 20px;
		nav {
			display: flex;
			align-items: center;
			justify-content: space-between;

			img {
				height: 50px;
			}
		}
	}

	.page-banner {
		color: white;
		.banner-content {
			position: absolute;
			top: 100px;
			left: 100px;
			width: calc(100% - 200px);

			.banner-title {
				font-size: 40px;
			}

			.banner-sologan {
				margin-top: 20px;
				font-size: 18px;
			}

			.banner-btn {
				margin-top: 40px;
				border-radius: 5px;
				border: 1px solid #409EFF;
				color: #409EFF;
				padding: 10px 20px;
				display: inline-block;
				cursor: pointer;
			}

			.banner-btn:hover {
				color: white;
				background-color: #409EFF;
			}
		}
	}
	@media screen and (max-width: 740px){
		.page-banner {
			.banner-content {
				top: 150px;
				left: 20px;
				width: calc(100% - 40px);
				display: flex;
				flex-direction: column;
				align-items: center;
				.banner-title {
					font-size: 24px;
					text-align: center;
				}
		
				.banner-sologan {
					font-size: 18px;
					font-weight: 200;
					text-align: center;
				}
				.banner-btn {
					font-size: 14px;
				}
			}
		}
	}
	@media screen and (max-width: 375px){
		.page-banner {
			.banner-content {
				.banner-title {
					font-size: 20px;
				}
				.banner-sologan {
					font-size: 14px;
				}
				.banner-btn {
					font-size: 12px;
				}
			}
		}
	}
	.page-partner {
		margin-top: 30px;
		display: flex;
		flex-direction: column;
		align-items: center;

		.partners-title {
			font-size: 24px;
			font-weight: bold;
			margin-bottom: 20px;
		}

		.partners-logos {
			img {
				margin: 0 20px;
				width: 60px;
				height: 60px;
			}
		}
	}

	.page-contact-us {
		div {
			width: 100%;
		}
		margin-top: 30px;
		padding: 20px;
		background-color: #333;
		display: flex;
		flex-direction: column;
		align-items: center;

		.page-contact-us-title {
			color: white;
			font-weight: 700;
			text-align: center;
			font-size: 24px;
			margin: 20px;
		}

		.page-contact-us-form {
			display: flex;
			flex-direction: row;
			.el-form {
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: center;
				.el-form-item {
					max-width: 400px;
					position: relative;
					.el-form-item__content {
						.el-button {
							position: absolute;
							top:0;
							right: 0;
							
						}
					}
				}
			}
		}
		.page-contact-us-result {
			text-align: center;
			color: white;
			max-width: 400px;
			font-size: 14px;
		}
	}

	.page-sologan {
		text-align: center;
		padding: 20px 0;
		font-weight: 700;
		font-size: 20px;
		margin: 30px
	}
	.page-footer {
		height: 40px;
		background-color: #333;
		display: flex;
		flex-direction: column;
		color: white;
		font-size: 12px;
		line-height: 40px;
		text-align: center;
		font-weight: 200;
	}
	.login-dialog {
		div.el-dialog {
			max-width: 350px;
			width: calc(100vw - 40px);
			div.el-dialog__header {
				padding-bottom: 0;
				button.el-dialog__headerbtn {
					z-index: 1000;
				}
			}
			div.el-dialog__body {
				padding: 0;
			}
		}
	}
</style>